(function ($) {
  $(document).on('product.init', '.js-product', function () {
    var $imageContainer = $('.js-product-full__image-carousel', $(this));

    if ($imageContainer.length) {
      Drupal.behaviors.productFullImageV1.initCarousel($imageContainer);
    }
  });
  $(document).on('product.image.reinit', '.js-product', function () {
    var $imageContainer = $('.js-product-full__image-carousel', $(this));

    if ($imageContainer.length) {
      Drupal.behaviors.productFullImageV1.initCarousel($imageContainer);
    }
  });

  Drupal.behaviors.productFullImageV1 = {
    attached: false,
    attach: function (context) {
      var self = this;
      if (self.attached) {
        return;
      }
      self.attached = true;
      // images
      var $sppImages = $('.js-product-full__image-carousel', context);
      var $carousel = $sppImages.find('.js-spp-carousel');

      // SPP image Carousel
      $sppImages.each(function () {
        var $thisFormatter = $(this);
        self.initCarousel($thisFormatter);
      });

      $(document).on('quickshop.loaded', function () {
        $carousel.slick('setPosition');
      });
    },
    initCarousel: function ($thisFormatter) {
      var $carousel = $thisFormatter.find('.js-spp-carousel');
      var arrowsDiv = $thisFormatter.parent().find('.carousel-controls');
      var dotsDiv = $thisFormatter.find('.carousel-dots');
      var sppSlide = '.js-spp-carousel__slide';
      // case for custom slides count and dots for mobile
      var settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        slide: sppSlide,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        appendArrows: arrowsDiv,
        appendDots: dotsDiv
      };
      // Init this carousel with our settings.
      $carousel.not('.slick-initialized').slick(settings);
    }
  };
})(jQuery);
